import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PrivateMainContainer from "./Containers/PrivateMainContainer";
import {Switch} from "react-router";
import PublicMainContainer from "./Containers/PublicMainContainer";

function App() {
    return (
        <Router>
            <Switch>
                <Route path={"/m"}>
                    <PrivateMainContainer/>
                </Route>
                <Route path={"/"}>
                    <PublicMainContainer/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
