import * as React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import './container.css';
import Navigation from "../../Components/Navigation";
import {Link} from "react-router-dom";
import {APP_LOGOUT_PATH} from "../../types";
import NachaFileViewer from "../../Components/NachaFileViewer";


const PrivateMainContainer: React.FunctionComponent = () => {

    let {path} = useRouteMatch();

    return (
        <>
            <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <div className="navbar-brand col-md-3 col-lg-2 mr-0 px-3">
                     <strong><a className={"text-white"} href={"/"}>Nacha Viewer</a></strong> <em>by <a className={"text-white"} href={"https://cabarrusit.com"}> cabarrusit.com</a></em></div>
                <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                        data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <input className="form-control form-control-dark w-100" type="text" placeholder="Search"
                       aria-label="Search"/>
                <ul className="navbar-nav px-3">
                    <li hidden={true} className="nav-item text-nowrap">
                        <Link className="nav-link" to={APP_LOGOUT_PATH}>Sign out</Link>
                    </li>
                </ul>
            </nav>

            <div className="container-fluid">
                <div className="row">
                    <Navigation/>

                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10">

                        <Switch>

                            <Route path={path + "/nacha-viewer"}>
                                <NachaFileViewer />
                            </Route>


                        </Switch>

                    </main>
                </div>
            </div>
        </>
    );

}

export default PrivateMainContainer;