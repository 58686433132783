import * as React from 'react';
import './nachaFileViewer.css'
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Field, NachaFieldSpec, Record} from "../../types";
import {getNachaSpecification, getSampleNachaFile} from "../../api";


const NachaFileViewer: React.FunctionComponent = () => {

    const [x, setX] = useState();
    const [y, setY] = useState();
    const [columnSelect, setColumnSelect] = useState(false);
    const [file, setFile] = useState<File>();
    const [nachaFile, setNachaFile] = useState("");
    const [highlightStartX, setHighlightStartX] = useState();
    const [highlightEndX, setHighlightEndX] = useState();
    const [highlightRecordTypeCode, setHighlightRecordTypeCode] = useState();
    const [nachaSpecification, setNachaSpecification] = useState<Record[]>();
    const LOADED_FILE = "com.moderneft-file";

    const {location} = useHistory();




    useEffect(() => {
        if (file !== undefined) {
            file.text()
                .then((t) => {
                    localStorage.setItem(LOADED_FILE, t);
                    setNachaFile(t);
                })
                .catch((e) => console.log(e))
        }
    }, [file]);

    useEffect(() => {
        getNachaSpecification()
            .then((r) => {
                r.forEach(r => {
                    r.fields.forEach(f => {
                        f.recordTypeCode = r.recordTypeCode;
                    })
                })
                setNachaSpecification(r);
            })
            .catch((e) => console.error(e.message));
    }, []);

    const getField = (path: string): Field => {
        const pathz = path.split("/")
        const filter = nachaSpecification?.filter(r => r.path === pathz[0])
            .flatMap(r => r.fields)
            .filter(f => f.path === pathz[1]);
        if(filter && filter.length) {
            return filter[0];
        }

        throw new Error("Could not find field");
    }


    useEffect(() => {
        if (location.pathname.startsWith("/m/nacha-viewer/nacha-specification")) {
            const path = location.pathname.replace("/m/nacha-viewer/nacha-specification/", "");

            try {
                const field = getField(path);
                console.log(field)
                setHighlightStartX(field.positionStart - 1);
                setHighlightEndX(field.positionEnd - 1);
                setHighlightRecordTypeCode(field.recordTypeCode + "");
            } catch (e) {
                //console.error(e);
            }
        } else {
            setHighlightStartX(null);
            setHighlightEndX(null);
            setHighlightRecordTypeCode(null);
        }
    }, [location, nachaSpecification]);


    useEffect(() => {
        const content = localStorage.getItem(LOADED_FILE);
        if (content !== undefined && content !== null) {
            setNachaFile(content);
        } else {
            getSampleNachaFile()
                .then(async (r) => {
                    setNachaFile(await r.text())
                })
                .catch((e) => console.error(e));

        }

    }, []);

    const getSpecFieldDescription = (path: string): NachaFieldSpec => {
        if (path === "/nacha-specification/file-header-record/record-type-code") {
            return {
                field: 1,
                positionStart: 1,
                positionEnd: 1,
                size: 1,
                recordTypeCode: "1",
                name: "Record Type Code",
                description: "Code identifying the File Header Record is \"1\""
            }
        }
        if (path === "/nacha-specification/file-header-record/priority-code") {
            return {
                field: 2,
                positionStart: 2,
                positionEnd: 3,
                size: 2,
                recordTypeCode: "1",
                name: "Priority Code",
                description: "Currently only \"01\" is used"
            }
        }
        if (path === "/nacha-specification/file-header-record/immediate-destination") {
            return {
                field: 3,
                positionStart: 4,
                positionEnd: 13,
                size: 10,
                recordTypeCode: "1",
                name: "Immediate Destination",
                description: "Routing number of immediate destination i.e. typically the financial institution" +
                    " originating the file"
            }
        }
        if (path === "/nacha-specification/file-header-record/immediate-origin") {
            return {
                field: 4,
                positionStart: 14,
                positionEnd: 23,
                size: 10,
                recordTypeCode: "1",
                name: "Immediate Origin",
                description: "Company id of immediate origin i.e. typically the company id of financial " +
                    "institution or business originating file"
            }
        }
        if (path === "/nacha-specification/file-header-record/file-creation-date") {
            return {
                field: 5,
                positionStart: 24,
                positionEnd: 29,
                size: 6,
                recordTypeCode: "1",
                name: "File Creation Date",
                description: "The date the file is created or transmitted"
            }
        }
        if (path === "/nacha-specification/file-header-record/file-creation-time") {
            return {
                field: 6,
                positionStart: 30,
                positionEnd: 33,
                size: 4,
                recordTypeCode: "1",
                name: "File Creation Time",
                description: "The time of day the file is created or transmitted"
            }
        }
        if (path === "/nacha-specification/file-header-record/file-id-modifier") {
            return {
                field: 7,
                positionStart: 34,
                positionEnd: 34,
                size: 1,
                recordTypeCode: "1",
                name: "File ID Modifier",
                description: "Code to distinguish among multiple input files sent per day"
            }
        }
        if (path === "/nacha-specification/file-header-record/record-size") {
            return {
                field: 8,
                positionStart: 35,
                positionEnd: 37,
                size: 3,
                recordTypeCode: "1",
                name: "Record Size",
                description: "Number of bytes per record, always 94"
            }
        }
        if (path === "/nacha-specification/file-header-record/blocking-factor") {
            return {
                field: 9,
                positionStart: 38,
                positionEnd: 39,
                size: 2,
                recordTypeCode: "1",
                name: "Blocking Factor",
                description: "Number of records per block"
            }
        }
        if (path === "/nacha-specification/file-header-record/format-code") {
            return {
                field: 10,
                positionStart: 40,
                positionEnd: 40,
                size: 1,
                recordTypeCode: "1",
                name: "Format code",
                description: "Only \"1\" is used"
            }
        }
        if (path === "/nacha-specification/file-header-record/destination") {
            return {
                field: 11,
                positionStart: 41,
                positionEnd: 63,
                size: 23,
                recordTypeCode: "1",
                name: "Destination",
                description: "Destination, typically bank name or it's processing facility"
            }
        }
        if (path === "/nacha-specification/file-header-record/origin-company-name") {
            return {
                field: 12,
                positionStart: 64,
                positionEnd: 86,
                size: 23,
                recordTypeCode: "1",
                name: "Origin or Company Name",
                description: "Company name including spaces"
            }
        }
        if (path === "/nacha-specification/file-header-record/reference-code") {
            return {
                field: 13,
                positionStart: 87,
                positionEnd: 94,
                size: 8,
                recordTypeCode: "1",
                name: "Reference Code",
                description: "Reference code for internal accounting purposes"
            }
        }

        if (path === "/nacha-specification/file-control-record/record-type-code") {
            return {
                field: 1,
                positionStart: 1,
                positionEnd: 1,
                size: 1,
                recordTypeCode: "9",
                name: "Record Type Code",
                description: "Code identifying the File Header Record is \"1\""
            }
        }

        throw new Error("invalid path");
    }

    const onKeyDown = (e: KeyboardEvent) => {

        switch (e.key) {
            case "ArrowLeft":
                e.preventDefault();
                if (x >= 1) {
                    setX(x - 1);
                }
                return;
            case "ArrowRight":
                e.preventDefault();
                if (x >= 0) {
                    setX(x + 1);
                }
                return;
            case "ArrowDown":
                e.preventDefault();
                if (y >= 0 && !columnSelect) {
                    setY(y + 1);
                }
                return;
            case "ArrowUp":
                e.preventDefault();
                if (y >= 1 && !columnSelect) {
                    setY(y - 1);
                }
                return;

        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown)
        }
    }, [x, y])

    const onColClick = (x: number) => {
        setX(x);
        setColumnSelect(true);
    }

    const onTdClick = (x: number, y: number) => {
        setColumnSelect(false);
        setX(x);
        setY(y);
    }

    let headers = [];
    for (let i = 1; i <= 94; i++) {
        headers.push(i);
    }

    const getCellClasses = (row: number, col: number, recordTypeCode: string) => {
        let className = "";
        if ((y === (row + 1) && x === col) || (x === col && columnSelect)) {
            className += "cell selected-cell";
        } else {
            className += "cell";
        }
        if (recordTypeCode === highlightRecordTypeCode && (col >= highlightStartX && col <= highlightEndX)) {
            if (col === highlightStartX) {
                className += " highlight-start";
            }
            if (col === highlightEndX) {
                className += " highlight-end";
            } else {
                className += " highlight";
            }
        }
        return className;
    }

    return (<>
        <header className={"menu"}>
            {/*<span className={"badge-pill badge badge-primary"}*/}
            {/*    onClick={onClickOpenFile}*/}
            {/*>Open file</span>*/}
            <input type={"file"} onChange={e => {
                setFile(e.target?.files?.[0]);
            }}/>
        </header>

        <article>
            <div className={"row-ruler"}>
                {headers.map((h, index) => (
                    <div key={"header_" + index} className={"cell"}
                         onClick={(e) => onColClick(index)}
                    >{h}</div>
                ))}
            </div>
            {nachaFile.split("\n").map((line, rowIndex) => (
                <div key={"row_" + rowIndex} className={"row-line"}>
                    {line.split("").map((h, colIndex) => (
                        <div
                            key={"row_" + rowIndex + "col_" + colIndex}
                            className={getCellClasses(rowIndex, colIndex, line.charAt(0))}
                            onClick={(e) => onTdClick(colIndex, rowIndex + 1)}>
                            {(h !== " ") ? h : '\u00A0'}
                        </div>
                    ))}
                </div>
            ))}

        </article>
    </>);
};

export default NachaFileViewer;