export const AUTH_USER_EMAIL_KEY = 'app.email';
export const AUTH_USER_ID_KEY = 'app.user.id';
export const AUTH_USER_EMAIL_CONFIRMED_KEY = 'app.email_confirmed';
export const APP_PORTFOLIO_ID = 'app.portfolio.id';
export const APP_LANDING = '/';
export const APP_ACTION_ITEMS = '/a/action-items';
export const APP_LOGIN_PATH = '/a/login';
export const APP_LOGOUT_PATH = '/a/logout';
export const APP_CONFIRM_EMAIL_PATH = '/a/confirm-email';
export const APP_RESET_PASSWORD_PATH = '/a/reset-password';

export type FormRegex = {
    expression: RegExp,
    helpText: string
}


export enum BootstrapValidateClass {
    isvalid = "is-valid", isinvalid = "is-invalid", unvalidated = ""
}


export type FormElementValidation = {
    id: string,
    validate: ValidateElementFunction,
    regex: FormRegex,
    required: boolean
}

export type ValidateElementFunction = (value: string, required: boolean, validation: RegExp) => boolean;

export const formInputValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (required) {
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    } else {
        if ((value && value.length === 0) || !value) {
            return true;
        }
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    }
}

export const formSelectValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (value && value.match(validation)) {
        return true;
    }
    return false;
}

export type Role = {
    name: string
}

export type AppUser = {
    username: string,
    roles: Role,
    actions?: Action[],
    projects: string[]
}

export type Action = {
    code: string,
    description: string
}

export type MenuItem = {
    label: string,
    path: string,
    menuItems: MenuItem[],
    icon?: string,
    weight?: number,
    parent?: MenuItem,
    showCard: boolean,
    field?: Field
}

export type NachaFieldSpec = {
    recordTypeCode?: string,
    field: number,
    positionStart: number,
    positionEnd: number,
    size: number,
    name: string
    description: string
}

export type Feature = {
    name: string
}

export type FileResponse = {
    name: string,
    uuid: string,
    size: number
}

export type Deployment = {
    name: string,
    uuid?: string,
    profile: string,
    createdAt?: string
    artifacts: string [],
    container?: Container
}

export type Container = {
    Id: string,
    Image: string,
    Command: string,
    Created: string
    Names: string[],
    State: string,
    Status: string,
    Labels: Object,
    Ports: {
        PrivatePort: string,
        Type: string
    }[],
    NetworkSettings: {
        Networks: {
            bridge: {
                NetworkID: string,
                Gateway: string,
                IPAddress: string
            }
        }
    }
}

export type Router = {
    uuid: string,
    httpPort: string,
    httpsPort: string,
    projects: Project[],
    status: string
    hostnamePrefix: string
}

export type Project = {
    name: string
    routes: Route[]
}

export type Route = {
    url: string
}

export type Record = {
    recordTypeCode: number,
    name: string,
    path: string,
    fields: Field[]
}

export type Field = {
    recordTypeCode: number,
    fieldNumber: number,
    positionStart: number,
    positionEnd: number,
    size: number,
    name: string,
    description: string,
    path: string
}

