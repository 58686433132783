import * as React from 'react';
import './container.css'

const PublicMainContainer: React.FunctionComponent = () => {


    return (<>






        <div className="container py-3">
            <header>
                <div className="row border-bottom">
                    <div className={"col"}>
                    <a href="/" className="d-flex align-items-center link-body-emphasis text-decoration-none">
                        <img className="mb-2" src="/assets/dist/img/eft_tools_logo.png" width="150" alt="eft tools logo"/>
                    </a>
                    </div>
                    <div className={"col text-right"}>
                    <nav className="d-inline-flex" style={{fontSize: 15}}>
                        <a className="mr-3 pt-3 text-decoration-none" href="tel:+19805504346">980-550-4346</a>
                        <a className="pt-3 text-decoration-none" href="mailto:sales@cabarrusit.com">sales@cabarrusit.com</a>
                    </nav>
                    </div>
                </div>

                <div className="pricing-header p-5 pb-md-4 mx-auto text-center">
                    <h1 className="display-4 fw-normal text-body-emphasis">Nacha File Inspector</h1>
                    <p className="fs-5 text-body-secondary">Quickly inspect ACH Nacha files with
                        an easy-to-use interface designed to enhance understanding, readability, and debugging.
                        Easily navigate through the file structure with intuitive key-bindings while accessing detailed
                        explanations of each record data element directly from the Nacha specification.
                        This tool not only improves comprehension of Nacha files but also enhances
                        readability, making it easier to identify and resolve issues during development and debugging.
                        By providing clear insights into the file's contents, this tool ensures adherence to
                        industry standards and regulatory requirements while streamlining the handling of ACH transactions.</p>
                </div>
            </header>

            <main>
                <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Free</h4>
                            </div>
                            <div className="card-body">
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>All Features Included</li>
                                    <li>Free to Use for Personal and Business</li>
                                    <li>Email Support</li>
                                </ul>
                                <a href={"/m/nacha-viewer"}><button type="button" className="w-100 btn btn-lg btn-outline-primary">Start Session</button></a>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-bg-primary border-primary">
                                <h4 className="my-0 fw-normal">Enterprise</h4>
                            </div>
                            <div className="card-body">
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Enterprise Support</li>
                                    <li>Host in Your Organization</li>
                                    <li>Phone and Email Support</li>
                                </ul>
                                <a href="mailto:sales@cabarrusit.com"><button type="button" className="w-100 btn btn-lg btn-primary">sales@cabarrusit.com</button></a>
                            </div>
                        </div>
                    </div>
                </div>


            </main>

            <footer className="pt-4 my-md-5 pt-md-5 border-top">
                <div className="row">
                    <div className="col-12 col-md">
                        <img className="mb-2" src="/assets/dist/img/eft_tools_logo.png" width="150" alt="eft tools logo"/>
                            <small className="d-block mb-3 text-body-secondary">&copy; 2024</small>
                    </div>
                    <div className="col-6 col-md">
                        <h5>Features</h5>
                        <ul className="list-unstyled text-small">
                            <li className="mb-1">Inspect Nacha Files</li>
                            <li className="mb-1">Click to View Spec</li>
                            <li className="mb-1">Navigate with Keyboard</li>
                            <li className="mb-1">Free to Use Public Version</li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>Resources</h5>
                        <ul className="list-unstyled text-small">

                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://www.nacha.org/">Nacha Organization</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://achdevguide.nacha.org/ach-file-overview">ACH Overview</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://files.nc.gov/ncosc/documents/eCommerce/bank_of_america_nacha_file_specs.pdf">Specification by nc.gov</a></li>
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://www.chase.com/content/dam/chaseonline/en/demos/cbo/pdfs/cbo_nacha_filespecs.pdf">Specification by Chase Bank</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>About</h5>
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://cabarrusit.com">cabarrusit.com</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    </>);
};

export default PublicMainContainer;