import * as React from 'react';
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {Field, MenuItem, NachaFieldSpec, Record} from "../../types";
import "./navigation.css"
import {getNachaSpecification} from "../../api";
import { v4 as uuidv4 } from 'uuid';

const Navigation: React.FunctionComponent = () => {


    let location = useLocation();
    const [menuItems, setMenuItems] = useState<MenuItem>();
    const [cardContent, setCardContent] = useState("");
    const [nachaSpecification, setNachaSpecification] = useState<Record[]>()

    useEffect(() => {
        getNachaSpecification()
            .then((r) => {
                r.forEach(r => {
                    r.fields.forEach(f => {
                        f.recordTypeCode = r.recordTypeCode;
                    })
                })
                setNachaSpecification(r);
            })
            .catch((e) => console.error(e.message));
    }, []);

    const getSpecFieldDescription = (path: string): NachaFieldSpec => {
        if (path === "/nacha-specification/file-header-record/record-type-code") {
            return {
                field: 1,
                positionStart: 1,
                positionEnd: 1,
                size: 1,
                name: "Record Type Code",
                description: "Code identifying the File Header Record is \"1\""
            }
        }
        throw new Error("invalid path");
    }

    const getFieldsFromRecord = (fields: Field[], parentPath: string): MenuItem[] => {
        const menuItems: MenuItem[] = [];
        if (fields) {
            fields.forEach(f => {
                menuItems.push({
                    label: f.name,
                    path: "/nacha-specification/" + parentPath + "/" + f.path,
                    showCard: false,
                    menuItems: [],
                    field: f
                })
            })
        }

        return menuItems;
    }

    const getMenuItemsFromRecords = (records: Record[] | undefined): MenuItem[] => {
        const menuItems: MenuItem[] = [];
        nachaSpecification?.forEach((r) => {
            menuItems.push({
                label: r.name,
                path: "/nacha-specification/" + r.path,
                showCard: false,
                menuItems: getFieldsFromRecord(r.fields, r.path)
            })
        })
        return menuItems;

    }
    const getNachaSpecificationMenuItems = (): MenuItem => {
        return {
            label: "Menu",
            path: "/",
            showCard: false,
            menuItems: [{
                label: "Nacha Specification",
                path: "/nacha-specification",
                showCard: false,
                menuItems: getMenuItemsFromRecords(nachaSpecification)

            }]
        }

    }

    useEffect(() => {
        if (nachaSpecification) {
            setMenuItems(getNachaSpecificationMenuItems() || []);
        }
        // setMenuItems({
        //     label: "Menu",
        //     path: "/",
        //     showCard: false,
        //     menuItems: [{
        //         label: "Nacha Specification",
        //         path: "/nacha-specification",
        //         showCard: false,
        //         menuItems: [{
        //             label: "File Header Record",
        //             path: "/nacha-specification/file-header-record",
        //             showCard: false,
        //             menuItems: [{
        //                 label: "Record Type Code",
        //                 path: "/nacha-specification/file-header-record/record-type-code",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Priority Code",
        //                 path: "/nacha-specification/file-header-record/priority-code",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Immediate Destination",
        //                 path: "/nacha-specification/file-header-record/immediate-destination",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Immediate Origin",
        //                 path: "/nacha-specification/file-header-record/immediate-origin",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "File Creation Date",
        //                 path: "/nacha-specification/file-header-record/file-creation-date",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "File Creation Time",
        //                 path: "/nacha-specification/file-header-record/file-creation-time",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "File ID Modifier",
        //                 path: "/nacha-specification/file-header-record/file-id-modifier",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Record Size",
        //                 path: "/nacha-specification/file-header-record/record-size",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Blocking Factor",
        //                 path: "/nacha-specification/file-header-record/blocking-factor",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Format Code",
        //                 path: "/nacha-specification/file-header-record/format-code",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Destination",
        //                 path: "/nacha-specification/file-header-record/destination",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Origin or Company Name",
        //                 path: "/nacha-specification/file-header-record/origin-company-name",
        //                 menuItems: [],
        //                 showCard: false
        //             }, {
        //                 label: "Reference Code",
        //                 path: "/nacha-specification/file-header-record/reference-code",
        //                 menuItems: [],
        //                 showCard: false
        //             }]
        //         }, {
        //             label: "File Control Record",
        //             path: "/nacha-specification/file-control-record",
        //             menuItems: [{
        //                 label: "Record Type Code",
        //                 path: "/nacha-specification/file-control-record/record-type-code",
        //                 menuItems: [],
        //                 showCard: false
        //             }],
        //             showCard: false
        //         }]
        //     }]
        // })
    }, [nachaSpecification]);

    const getNachaFieldCard = (m: MenuItem) => {
        if (!m.field) {
            return <div>
                <p>No description available</p>
            </div>
        }
        return <div>
            <p>Field: {m.field.fieldNumber}</p>
            <p>Name: {m.field.name}</p>
            <p>Position: {m.field.positionStart + "-" + m.field.positionEnd}</p>
            <p>Size: {m.field.size}</p>
            <p>Description:</p>
            <pre style={{color: "#b7bcc0", fontSize: 14, fontFamily: "inherit"}}>{m.field.description}</pre>
        </div>

    }

    const highlightInFile = () => {

    }


    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse">
            <div className="sidebar-sticky">
                <header onClick={() => {
                    if (menuItems?.parent !== undefined) {
                        setMenuItems(menuItems?.parent);
                    }
                }}>
                    <span>

                        <span className={"label"}>
                        {menuItems?.label}
                        </span>
                        <span hidden={menuItems?.parent === undefined} className={"menu-item-icon"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-chevron-left" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        </span>
                    </span>

                </header>
                <ul className="nav flex-column">
                    {
                        menuItems?.menuItems.map((m => (
                            <div key={uuidv4()}>
                                <Link to={"/m/nacha-viewer" + m.path}>
                                    <li className={"nav-link"}
                                        onClick={() => {
                                            if (m.menuItems !== undefined && m.menuItems.length > 0) {
                                                m.parent = menuItems;
                                                setMenuItems(m);
                                            } else {
                                                menuItems.menuItems.forEach(i => i.showCard = false);
                                                m.showCard = true;
                                                setMenuItems(Object.create(menuItems));
                                            }
                                        }}>
                                        <span data-feather="layers">{m.label}</span>
                                        <span hidden={m.menuItems === undefined || m.menuItems.length <= 0}
                                              className={"menu-item-icon"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor"
                                                 className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </span>
                                    </li>
                                    <li hidden={!m.showCard} className={"nav-link menu-card"}>
                                        {getNachaFieldCard(m)}
                                    </li>
                                </Link>
                            </div>
                        )))
                    }
                    <li className={"nav-link text-white"}>
                        Your data is always kept locally in your browser and never shared.
                    </li>
                    <li className={"nav-link text-white"}>
                        For enterprise support and to host locally in your organization call <a href={"tel:+19805504346"}>980-550-4346</a> or email <a href={"mailto:sales@cabarrusit.com"}>sales@cabarrusit.com</a>
                    </li>


                    {/*<li>*/}
                    {/*    <Link*/}
                    {/*        className={"nav-link"}*/}
                    {/*        to={"/"}>*/}
                    {/*        <span data-feather="layers">My Files</span>*/}

                    {/*        <span className={"menu-item-icon"}>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*                 className="bi bi-chevron-right" viewBox="0 0 16 16">*/}
                    {/*                <path fill-rule="evenodd"*/}
                    {/*                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>*/}
                    {/*            </svg>*/}
                    {/*        </span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link*/}
                    {/*        className={"nav-link"}*/}
                    {/*        to={"/"}>*/}
                    {/*        <span data-feather="layers">Nacha Specification</span>*/}

                    {/*        <span className={"menu-item-icon"}>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*                 className="bi bi-chevron-right" viewBox="0 0 16 16">*/}
                    {/*                <path fill-rule="evenodd"*/}
                    {/*                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>*/}
                    {/*            </svg>*/}
                    {/*        </span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}


                </ul>

            </div>
        </nav>
    );
};

export default Navigation;