import {currentSession} from "./Auth";
import {AppUser, Deployment, Feature, FileResponse, Project, Record, Router} from "./types";

declare global {
    interface Window {
        com_stackleader_core_api_url: string;
    }
}

const coreApiEndpoint = window.com_stackleader_core_api_url;

enum HttpMethod {
    GET = "GET", HEAD = "HEAD", POST = "POST", PUT = "PUT", PATCH = "PATCH", DELETE = "DELETE", OPTIONS = "OPTIONS", TRACE = "TRACE"
}

// const serialize = (obj: any) => {
//     const str = [];
//     for (const p in obj)
//         if (obj.hasOwnProperty(p)) {
//             if (obj[p] && obj[p].length > 0) {
//                 str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//             }
//         }
//     return str.join("&");
// }

function handleResponse(response: Response) {
    if (response.ok) {
        return response.json();
    } else {
        return response.json().then(function (error) {
            throw error;
        });
    }
}


function handleNetworkError(error: Error) {
    throw error;
}

type getSampleNachaFile = () => Promise<Response>;

type getNachaSpecification = () => Promise<Record[]>;

export const getSampleNachaFile: getSampleNachaFile = (() => {
    return fetch("/data/nacha-file-example-1.txt", {
        mode: 'cors',
        credentials: 'include',
        headers: {
            Pragma: 'no-cache'
        },
        method: "GET"
    })
});

export const getNachaSpecification: getNachaSpecification = (() => {
    return fetch("/data/nacha-specification.json", {
        mode: 'cors',
        credentials: 'include',
        headers: {
            Pragma: 'no-cache'
        },
        method: "GET"
    })
        .then(handleResponse, handleNetworkError)
});